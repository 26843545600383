<template>
  <div>
    <h3 class="text-align-center mb-3">
      {{ $t('graph.service-graph') }} - {{ selectedDataType.displayName }}
    </h3>
    <div v-loading="loading"
      class="flex-column full-width full-height flex-align-center flex-justify-center">
      <div class="text-align-center mb-1">
        <span class="mr-1">{{ $t('graph.date-range') }}</span>
        <el-date-picker v-model="graphDateRange"
          class="mr-1 text-align-center"
          popper-class="graph-date-range"
          unlink-panels
          format="yyyy-MM-dd HH:mm"
          type="datetimerange"
          value-format="timestamp"
          :range-separator="$t('general.to')"
          :clearable="false"
          size="small"
          :picker-options="pickerOptions"
          @change="fetchGraph" />
        <span class="mr-1">{{ $t('graph.metrics') }}</span>
        <el-select v-model="graphDataType"
          v-loading="lodadingDataTypes"
          size="small"
          class="mr-1 metricSelect"
          @change="fetchGraph(true)">
          <el-option v-for="type in graphDataTypes"
            :key="type.name"
            :value="type.name"
            :label="type.displayName" />
        </el-select>
        <template v-if="serviceType === 'VXC' && !isTransitVxc">
          <span class="mr-1">{{ $t('graph.source') }}</span>
          <el-select v-model="graphEnd"
            class="mr-1 sourceSelect"
            size="small"
            @change="fetchGraph">
            <el-option v-for="option in endOptions"
              :key="option.end"
              :value="option.end"
              :label="option.display" />
          </el-select>
        </template>
        <service-utilisation-export-button v-if="showGraph"
          :product-uid="service.productUid"
          :product-type="service.productType"
          :data-type="graphDataTypeWithEnd"
          :from="startDate"
          :to="endDate"
          :button-text="$t('graph.export-csv')"
          @downloading="(isDownloading) => loading = isDownloading" />
      </div>
      <div v-if="dataLoadError"
        class="info-bar">
        {{ $t('graph.load-error') }}
      </div>
      <div v-else-if="!graphData"
        class="info-bar">
        {{ $t('graph.no-data') }}
      </div>
      <div v-else-if="selectedDataType"
        class="flex-column full-width full-height">
        <div class="text-align-center">
          <el-checkbox v-model="graphIn"
            class="graphInColor">
            {{ `${selectedDataType.subtypes[0]} ${selectedDataType.outputUnit.name}` }}
          </el-checkbox>
          <el-checkbox v-if="selectedDataType.subtypes.length > 1"
            v-model="graphOut"
            class="graphOutColor">
            {{ `${selectedDataType.subtypes[1]} ${selectedDataType.outputUnit.name}` }}
          </el-checkbox>
          <el-checkbox v-if="selectedDataType.subtypes.length > 2"
            v-model="graphSpeed"
            class="graphSpeedColor">
            {{ `${selectedDataType.subtypes[2]} ${selectedDataType.outputUnit.name}` }}
          </el-checkbox>
          <el-checkbox v-if="selectedDataType.name === 'OPTICAL_100G'"
            v-model="graphLane4"
            class="graphLane4Color">
            {{ `${selectedDataType.subtypes[3]} ${selectedDataType.outputUnit.name}` }}
          </el-checkbox>
          <el-checkbox v-if="selectedDataType.name === 'OPTICAL_100G'"
            v-model="graphLane5"
            class="graphLane5Color">
            {{ `${selectedDataType.subtypes[4]} ${selectedDataType.outputUnit.name}` }}
          </el-checkbox>
          <el-checkbox v-if="selectedDataType.name === 'OPTICAL_100G'"
            v-model="graphLane6"
            class="graphLane6Color">
            {{ `${selectedDataType.subtypes[5]} ${selectedDataType.outputUnit.name}` }}
          </el-checkbox>
          <el-checkbox v-if="selectedDataType.name === 'OPTICAL_100G'"
            v-model="graphLane7"
            class="graphLane7Color">
            {{ `${selectedDataType.subtypes[6]} ${selectedDataType.outputUnit.name}` }}
          </el-checkbox>
          <el-checkbox v-if="selectedDataType.name === 'OPTICAL_100G'"
            v-model="graphLane8"
            class="graphLane8Color">
            {{ `${selectedDataType.subtypes[7]} ${selectedDataType.outputUnit.name}` }}
          </el-checkbox>
        </div>
        <vue-plotly v-if="showGraph"
          :data="processedGraphData"
          :layout="graphLayout"
          :options="graphOptions"
          :auto-resize="true"
          class="plotly-graph" />
        <div v-if="showGraph"
          class="text-align-center mb-1">
          <span class="mr-1">{{ $t('graph.timezone') }}</span>
          <el-radio-group v-model="useLocalTime"
            size="small"
            @change="fetchGraph">
            <el-radio-button :label="false">
              {{ $t('graph.utc') }}
            </el-radio-button>
            <el-radio-button :label="true">
              {{ localTimezone }}
            </el-radio-button>
          </el-radio-group>
        </div>
        <div v-else-if="!graphIn && (!graphOut && selectedDataType.subtypes.length > 1) && (!graphSpeed && selectedDataType.subtypes.length > 2)"
          class="info-bar">
          {{ $t('graph.nothing-selected') }}
        </div>
      </div>
    </div>
    <hr>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import { mapState, mapGetters } from 'vuex'
import sdk from '@megaport/api-sdk'
import captureSentryError from '@/utils/CaptureSentryError.js'
import { readCssVar } from '@/utils/CssVars.js'

import ServiceUtilisationExportButton from '@/components/ServiceUtilisationExportButton.vue'
// Date picker being used returns [ start, end ] date as the value for the date range
// so use a const to make code clearer
const START_DATE_INDEX = 0
const END_DATE_INDEX = 1

export default {
  name: 'ServiceGraph',

  components: {
    'vue-plotly': () => import(/* webpackChunkName: "VuePlotLy" */ '@statnett/vue-plotly'),
    'service-utilisation-export-button': ServiceUtilisationExportButton,
  },

  props: {
    service: {
      type: Object,
      required: true,
    },
    flip: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      graphDateRange: [
        DateTime.now().minus({ hours: 24 }).toMillis(), // startDate
        DateTime.now().toMillis(), // endDate
      ],
      pickerOptions: {
        shortcuts: [
          {
            text: this.$tc('graph.last-hour'),
            onClick(picker) {
              const end = DateTime.now().toMillis()
              const start = DateTime.now().minus({ hours: 1 }).toMillis()
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: this.$tc('graph.last-hour', 12),
            onClick(picker) {
              const end = DateTime.now().toMillis()
              const start = DateTime.now().minus({ hours: 12 }).toMillis()
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: this.$tc('graph.last-hour', 24),
            onClick(picker) {
              const end = DateTime.now().toMillis()
              const start = DateTime.now().minus({ hours: 24 }).toMillis()
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: this.$tc('graph.last-week'),
            onClick(picker) {
              const end = DateTime.now().toMillis()
              const start = DateTime.now().minus({ week: 1 }).toMillis()
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: this.$tc('graph.last-month'),
            onClick(picker) {
              const end = DateTime.now().toMillis()
              const start = DateTime.now().minus({ months: 1 }).toMillis()
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: this.$tc('graph.last-month', 3),
            onClick(picker) {
              const end = DateTime.now().toMillis()
              const start = DateTime.now().minus({ months: 3 }).toMillis()
              picker.$emit('pick', [start, end])
            },
          },
        ],
      },
      // Expose Constants
      START_DATE_INDEX,
      END_DATE_INDEX,

      graphData: null,
      loading: true,
      dataLoadError: false,
      useLocalTime: false,

      // Graph dataset visibility
      graphIn: true,
      graphOut: true,
      graphSpeed: false,

      // Optical 100G graph data
      graphLane4: true,
      graphLane5: true,
      graphLane6: true,
      graphLane7: true,
      graphLane8: true,

      // Graph min/max ranges
      graphInMin: null,
      graphInMax: null,
      graphOutMin: null,
      graphOutMax: null,
      graphSpeedMin: null,
      graphSpeedMax: null,

      // Optical 100G graph min/max ranges
      graphLane4Min: null,
      graphLane4Max: null,
      graphLane5Min: null,
      graphLane5Max: null,
      graphLane6Min: null,
      graphLane6Max: null,
      graphLane7Min: null,
      graphLane7Max: null,
      graphLane8Min: null,
      graphLane8Max: null,

      graphDataType: null,
      graphEnd: 'A',

      /**
       * The graph options as used by plotly
       */
      graphOptions: {
        responsive: true,
        displaylogo: false,
        displayModeBar: false,
      },

      lodadingDataTypes: true,
      graphDataTypes: null,
    }
  },

  computed: {
    ...mapGetters('Services', ['myPorts']),
    ...mapState('Services', ['servicesReady']),
    startDate() {
      return this.graphDateRange[START_DATE_INDEX]
    },
    endDate() {
      return this.graphDateRange[END_DATE_INDEX]
    },
    graphDataTypeWithEnd() {
      // If this is a VXC, we need the A/B end as the prefix of the data type.
      // This is so we can look at the data from the A or B end separately.
      // (kind of an awkward way to do that)
      return this.serviceType === this.G_PRODUCT_TYPE_VXC ? `${this.graphEnd}_${this.graphDataType}` : this.graphDataType
    },
    localTimezone() {
      return Intl.DateTimeFormat().resolvedOptions().timeZone
    },
    graphInColor() {
      return readCssVar('--mp-graph-in-color')
    },
    graphOutColor() {
      return readCssVar('--mp-graph-out-color')
    },
    graphSpeedColor() {
      return readCssVar('--mp-graph-speed-color')
    },
    graphLane4Color() {
      return readCssVar('--mp-graph-lane4-color')
    },
    graphLane5Color() {
      return readCssVar('--mp-graph-lane5-color')
    },
    graphLane6Color() {
      return readCssVar('--mp-graph-lane6-color')
    },
    graphLane7Color() {
      return readCssVar('--mp-graph-lane7-color')
    },
    graphLane8Color() {
      return readCssVar('--mp-graph-lane8-color')
    },
    /**
     * The graph data that is actually graphed by plotly
     */
    processedGraphData() {
      if (!this.graphData) {
        return []
      }

      // Get graph data and push to array to plot
      const stuffToGraph = []
      if (this.graphIn) {
        stuffToGraph.push(this.graphData.inGraph)
      }
      if (this.selectedDataType) {
        if (this.graphOut && this.selectedDataType.subtypes.length > 1) {
          stuffToGraph.push(this.graphData.outGraph)
        }
        if (this.graphSpeed && this.selectedDataType.subtypes.length > 2) {
          stuffToGraph.push(this.graphData.speedGraph)
        }
        if (this.graphLane4 && this.selectedDataType.subtypes.length > 3) {
          stuffToGraph.push(this.graphData.lane4Graph)
        }
        if (this.graphLane5 && this.selectedDataType.subtypes.length > 4) {
          stuffToGraph.push(this.graphData.lane5Graph)
        }
        if (this.graphLane6 && this.selectedDataType.subtypes.length > 5) {
          stuffToGraph.push(this.graphData.lane6Graph)
        }
        if (this.graphLane7 && this.selectedDataType.subtypes.length > 6) {
          stuffToGraph.push(this.graphData.lane7Graph)
        }
        if (this.graphLane8 && this.selectedDataType.subtypes.length > 7) {
          stuffToGraph.push(this.graphData.lane8Graph)
        }
      }
      return stuffToGraph
    },
    /**
     * The layout object that is used by plotly
     * @returns {object} Object containing options used to plot graph
     */
    graphLayout() {
      // Global x-axis options
      const xaxis = {
        type: 'date',
        tickmode: 'auto',
        linecolor: '#dcdfe6',
        linewidth: 1,
        mirror: true,
        title: this.$t('graph.date-time'),
        hoverformat: `%a %d %b %Y %H:%M ${this.useLocalTime ? this.localTimezone : 'UTC'}`,
      }

      const MAX_NTICKS = 28

      const startDate = DateTime.fromMillis(this.graphDateRange[START_DATE_INDEX])
      const endDate = DateTime.fromMillis(this.graphDateRange[END_DATE_INDEX])

      /*
       * Minutes is required here, as otherwise any extra milliseconds in the diff would
       * end up as part of the hours and convert it to a decimal value, which we don't want.
       */
      const duration = endDate.diff(startDate, ['years', 'months', 'days', 'hours', 'minutes']).toObject()

      // Changes the xaxis ticks depending on how long a duration we're displaying on the graph.
      // If across multiple months or years, we default to showing one tick per month (until its > MAX_NTICKS)
      if (duration.months >= 6 || duration.years >= 1) {
        xaxis.tickformat = '%m/%y'
        xaxis.nticks = Math.min(MAX_NTICKS, duration.months + (duration.years * 12))
      // show day month for > 1m && < 6m as it will skip days to be clearer.
      } else if (duration.months >= 1 && duration.months <= 6) {
        xaxis.tickformat = '%d/%m'
        xaxis.nticks = MAX_NTICKS
      // less or equal to 1 month one tick per day
      } else if (duration.days > 1 && duration.days <= 30) {
        xaxis.tickformat = '%d/%m'
        xaxis.nticks = duration.days
      // for the 1 hour view, display in 5m increments so 20 ticks
      } else if (duration.days === 0 && duration.hours === 1) {
        xaxis.tickformat = '%H:%M'
        xaxis.nticks = 20
      // less than a day but more than an hour, just display one tick per hour
      } else if (duration.days <= 1) {
        xaxis.tickformat = '%H:%M'
        xaxis.nticks = Math.ceil(duration.hours + (duration.days * 24))
      }

      // Global y-axis options
      const yaxis = {
        linecolor: '#dcdfe6',
        linewidth: 1,
        mirror: true,
        title: this.selectedDataType.outputUnit.name,
        typeMeasurement: this.selectedDataType.outputUnit.name,
      }

      // Add additional y-axis options depending on type of graph selected
      switch (this.graphDataType) {
        case 'BITS':
          yaxis.exponentformat = 'none'
          yaxis.rangemode = 'nonnegative'
          yaxis.autorange = true
          yaxis.tickmode = 'auto'
          yaxis.hoverformat = '0.5r'
          break
        case 'PACKETS':
          yaxis.exponentformat = 'none'
          yaxis.rangemode = 'nonnegative'
          yaxis.autorange = true
          yaxis.tickmode = 'auto'
          yaxis.hoverformat = ',.5r'
          break
        case 'ERRORS':
          {
            let max = null
            if (this.graphIn && this.graphOut) {
              max = Math.max(this.graphInMax, this.graphOutMax)
            } else if (this.graphOut) {
              max = this.graphOutMax
            } else {
              max = this.graphInMax
            }

            yaxis.exponentformat = 'none'
            yaxis.rangemode = 'nonnegative'
            yaxis.autorange = true
            yaxis.tickmode = 'linear'
            yaxis.tick0 = 0
            // Set up to have up to 10 tick marks at integer spacing, with a minimum of 1
            yaxis.dtick = Math.max(Math.ceil(max / 10), 1)
            yaxis.hoverformat = ',.1f'
          }
          break
        case 'OPTICAL':
          {
            let min = null
            let max = null
            if (this.graphIn && this.graphOut) {
              min = Math.min(this.graphInMin, this.graphOutMin)
              max = Math.max(this.graphInMax, this.graphOutMax)
            } else if (this.graphOut) {
              min = this.graphOutMin
              max = this.graphOutMax
            } else {
              min = this.graphInMin
              max = this.graphInMax
            }

            // If the range of values is really small, make the axis range 10
            // otherwise, just leave a small gap above and below.
            const diff = max - min
            let adjustment = 2
            if (diff < 10) {
              adjustment = (10 - diff) / 2
            }
            min = min - adjustment
            max = max + adjustment

            yaxis.exponentformat = 'none'
            yaxis.rangemode = 'normal'
            yaxis.autorange = false
            yaxis.range = [min, max]
            yaxis.tickmode = 'auto'
            yaxis.hoverformat = ',.3f'
          }
          break
        case 'OPTICAL_100G':
          {
            yaxis.exponentformat = 'none'
            yaxis.rangemode = 'normal'
            yaxis.autorange = true
            yaxis.tickmode = 'auto'
            yaxis.hoverformat = ',.3f'
          }
          break
        default:
          captureSentryError(new Error(`Unrecognized graph data type: ${this.graphDataType}`))
          break
      }
      return {
        autosize: true,
        xaxis: xaxis,
        yaxis: yaxis,
        margin: {
          l: 80,
          r: 50,
          t: 20,
          b: 60,
        },
        showlegend: false,
      }
    },
    serviceType() {
      return this.service.productType
    },
    /**
     * Returns true if service type is VXC or in transit
     */
    isTransitVxc() {
      if (this.service.productType === this.G_PRODUCT_TYPE_VXC && this.service.resources?.csp_connection?.connectType === 'TRANSIT') {
        return true
      }
      return false
    },
    /**
     * Return selected data type with axis units and lane subtype names
     * @returns {Object} Data type (eg. errors, optical, packets, traffic)
     */
    selectedDataType() {
      if (this.graphDataTypes) {
        const types = this.graphDataTypes.find(item => item.name === this.graphDataType) || {}
        // API doesn't return all lane subtypes so push them manually here
        if (types.name === 'OPTICAL_100G' && this.service.productType !== this.G_PRODUCT_TYPE_VXC) {
          types.subtypes = []
          types.subtypes.push('Tx Power Lane 1')
          types.subtypes.push('Tx Power Lane 2')
          types.subtypes.push('Tx Power Lane 3')
          types.subtypes.push('Tx Power Lane 4')
          types.subtypes.push('Rx Power Lane 1')
          types.subtypes.push('Rx Power Lane 2')
          types.subtypes.push('Rx Power Lane 3')
          types.subtypes.push('Rx Power Lane 4')
        }
        return types
      }
      return {}
    },
    /**
     * Only display graph if lanes contain data
     * @returns {boolean} If graph has data
     */
    showGraph() {
      return (
        this.graphData && (this.graphIn ||
        (this.graphOut && this.selectedDataType.subtypes.length > 1) ||
        (this.graphSpeed && this.selectedDataType.subtypes.length > 2) ||
        (this.graphLane4 && this.selectedDataType.name === 'OPTICAL_100G') ||
        (this.graphLane5 && this.selectedDataType.name === 'OPTICAL_100G') ||
        (this.graphLane6 && this.selectedDataType.name === 'OPTICAL_100G') ||
        (this.graphLane7 && this.selectedDataType.name === 'OPTICAL_100G') ||
        (this.graphLane8 && this.selectedDataType.name === 'OPTICAL_100G'))
      )
    },
    ixAEndPort() {
      if (this.service.productType !== this.G_PRODUCT_TYPE_IX) {
        return null
      }

      if (!this.service.parentPortUid || this.service.parentPortUid.length <= 0) {
        return {}
      }

      // The value may either be a string or an array that we want the first value from
      const portUid = typeof this.service.parentPortUid === 'string' ? this.service.parentPortUid : this.service.parentPortUid[0]
      return this.myPorts.find(port => port.productUid === portUid) || {}
    },
    endOptions() {
      return [
        {
          end: 'A',
          display: `${this.service.aEnd.productName} (${this.service.aEnd.location})`,
        },
        {
          end: 'B',
          display: `${this.service.bEnd.productName} (${this.service.bEnd.location})`,
        },
      ]
    },
  },

  /**
   * Plot initial graph
   */
  mounted() {
    if (this.servicesReady) this.loadGraphDataTypes()
  },

  methods: {
    loadGraphDataTypes() {
      sdk.instance
        .telemetryDataTypes()
        .then(types => {
          const typesForProduct = types.find(type => type.productType === this.serviceType)
          if (!typesForProduct) {
            captureSentryError(new Error(`Could not find graph types for product of type ${this.serviceType}`))
            return
          }
          const metrics = typesForProduct.metrics
            .filter(type => !type.name.startsWith('B_'))
            .map(type => {
              return { ...type, name: type.name.startsWith('A_') ? type.name.substring(2) : type.name }
            })
          this.graphDataTypes = metrics.sort((a, b) => (a.displayName > b.displayName ? 1 : -1))
          // Select the default data type to display first
          // If it's a VXC that we are viewing from the B end company, we will select the B bits by default
          if (this.flip) {
            this.graphEnd = 'B'
          } else {
            this.graphEnd = 'A'
          }
          const matchingType = this.graphDataTypes.find(type => type.name === 'BITS')
          if (matchingType) {
            this.graphDataType = matchingType.name
          }
          // Fallback to the first one if nothing else worked
          if (!this.graphDataType) {
            this.graphDataType = this.graphDataTypes[0].name
          }

          // Only show OPTICAL_100G if port is 100Gbps
          if (
            this.service.portSpeed === 100000 &&
            this.service.productType !== this.G_PRODUCT_TYPE_VXC
          ) {
            this.graphDataTypes.splice(this.graphDataTypes.findIndex(type => type.name === 'OPTICAL'), 1)
          } else if (this.graphDataTypes.find(type => type.name === 'OPTICAL_100G')) {
            this.graphDataTypes.splice(this.graphDataTypes.findIndex(type => type.name === 'OPTICAL_100G'), 1)
          }

          this.fetchGraph()
        })
        .catch(e => {
          captureSentryError(e)
        })
        .finally(() => {
          this.lodadingDataTypes = false
        })
    },
    /**
     * Get the raw graph data and save it, then pass on to processing so as to calculate
     * derived graph data.
     */
    fetchGraph(graphChange = false) {
      // Turn speed lane off when data type is traffic (BITS)
      if (graphChange) {
        if (this.graphDataType === 'BITS') {
          this.graphSpeed = false
        } else if (this.graphDataType === 'OPTICAL_100G') {
          this.graphSpeed = true
        }
      }

      // Get the object that we're talking to.
      const productObject = sdk.instance.product(this.service.productUid)
      // Grab the method so we can use common methodology for fetching data
      let telemetryMethod = null
      // Assemble the arguments for the method call
      let selectedEnd = ''
      if (this.serviceType === 'VXC') {
        selectedEnd = `${this.graphEnd}_`
      }
      const args = [`${selectedEnd}${this.graphDataType}`, this.graphDateRange[START_DATE_INDEX], this.graphDateRange[END_DATE_INDEX]]
      const earliestSampleTime = Math.ceil(this.graphDateRange[START_DATE_INDEX] / 300000) * 300000

      switch (this.serviceType) {
        case this.G_PRODUCT_TYPE_MEGAPORT:
          telemetryMethod = productObject.portTelemetry
          break
        case this.G_PRODUCT_TYPE_VXC:
          telemetryMethod = productObject.vxcTelemetry
          break
        case this.G_PRODUCT_TYPE_IX:
          telemetryMethod = productObject.ixTelemetry
          break
        case this.G_PRODUCT_TYPE_MCR2:
          telemetryMethod = productObject.mcr2Telemetry
          break
        case this.G_PRODUCT_TYPE_MVE:
          telemetryMethod = productObject.mveTelemetry
          break
        default:
          captureSentryError(new Error(`Unsupported service type: ${this.serviceType}`))
          this.loading = false
          return false
      }

      this.loading = true
      this.dataLoadError = false

      // Call the method identified above with the required arguments and process the results
      telemetryMethod
        .apply(productObject, args)
        .then(data => {
          const subtypes = this.selectedDataType.subtypes

          // Collect and assign data
          const inData = data.find(item => item.subtype === subtypes[0])
          const outData = subtypes.length > 1 ? data.find(item => item.subtype === subtypes[1]) : null
          const speedData = subtypes.length > 2 ? data.find(item => item.subtype === subtypes[2]) : null
          let lane4Data
          let lane5Data
          let lane6Data
          let lane7Data
          let lane8Data

          if (this.selectedDataType.name === 'OPTICAL_100G') {
            // Tx lane 4
            lane4Data = subtypes.length > 3 ? data.find(item => item.subtype === subtypes[3]) : null
            // Rx lane 1
            lane5Data = subtypes.length > 4 ? data.find(item => item.subtype === subtypes[4]) : null
            // Rx lane 2
            lane6Data = subtypes.length > 5 ? data.find(item => item.subtype === subtypes[5]) : null
            // Rx lane 3
            lane7Data = subtypes.length > 6 ? data.find(item => item.subtype === subtypes[6]) : null
            // Rx lane 4
            lane8Data = subtypes.length > 7 ? data.find(item => item.subtype === subtypes[7]) : null
          }

          // Build raw graph data object for processing
          const rawGraphData = {}
          let hasData = false
          if (inData && inData.samples.length) {
            rawGraphData.in = inData.samples
            hasData = true
          }
          if (outData && outData.samples.length) {
            rawGraphData.out = outData.samples
            hasData = true
          }
          if (speedData && speedData.samples.length) {
            rawGraphData.speed = speedData.samples
          }
          if (lane4Data && lane4Data.samples.length) {
            rawGraphData.lane4 = lane4Data.samples
            hasData = true
          }
          if (lane5Data && lane5Data.samples.length) {
            rawGraphData.lane5 = lane5Data.samples
            hasData = true
          }
          if (lane6Data && lane6Data.samples.length) {
            rawGraphData.lane6 = lane6Data.samples
            hasData = true
          }
          if (lane7Data && lane7Data.samples.length) {
            rawGraphData.lane7 = lane7Data.samples
            hasData = true
          }
          if (lane8Data && lane8Data.samples.length) {
            rawGraphData.lane8 = lane8Data.samples
            hasData = true
          }
          // Hide graph completly if there is no data
          if (!hasData) {
            this.graphData = null
            this.loading = false
            return false
          }
          this.graphData = this.processGraphData(rawGraphData, earliestSampleTime)
          this.loading = false
        })
        .catch(() => {
          // TODO: Should we be catching errors here and reporting/processing them?
          this.graphData = null
          this.loading = false
          this.dataLoadError = true
        })
    },
    /**
     * Make sure the graph starts from the start of the requested time window even if
     * the service was deployed after the start of the graph time.
     */
    padGraphData(pointArray, earliestSampleTime) {
      const lastItem = pointArray[pointArray.length - 1]
      if (lastItem[0] > earliestSampleTime) {
        pointArray.push([earliestSampleTime, null])
      }
    },
    /**
     * Processes the raw graph data to extract the information to present for the graph lines.
     */
    processGraphData(rawGraphData, earliestSampleTime) {
      const xAxis = []
      let doneXAxis = false

      // In data
      const inAxis = []
      this.graphInMin = null
      this.graphInMax = null
      if (rawGraphData.in) {
        this.padGraphData(rawGraphData.in, earliestSampleTime)
        doneXAxis = true
        this.graphInMin = this.graphInMax = rawGraphData.in[0][1]
        for (const dataPoint of rawGraphData.in) {
          inAxis.push(dataPoint[1])
          this.graphInMin = Math.min(this.graphInMin, dataPoint[1])
          this.graphInMax = Math.max(this.graphInMax, dataPoint[1])
          if (this.useLocalTime) {
            xAxis.push(DateTime.fromMillis(dataPoint[0])
              .set({ milliseconds: 0 })
              .toISO({ suppressMilliseconds: true }))
          } else {
            xAxis.push(DateTime.fromMillis(dataPoint[0])
              .set({ milliseconds: 0 })
              .toUTC()
              .toISO({ suppressMilliseconds: true }))
          }
        }
      }

      // Out data
      const outAxis = []
      this.graphOutMin = null
      this.graphOutMax = null
      if (rawGraphData.out) {
        this.padGraphData(rawGraphData.out, earliestSampleTime)
        this.graphOutMin = this.graphOutMax = rawGraphData.out[0][1]
        for (const dataPoint of rawGraphData.out) {
          outAxis.push(dataPoint[1])
          this.graphOutMin = Math.min(this.graphOutMin, dataPoint[1])
          this.graphOutMax = Math.max(this.graphOutMax, dataPoint[1])
          if (!doneXAxis) {
            doneXAxis = true
            if (this.useLocalTime) {
              xAxis.push(DateTime.fromMillis(dataPoint[0])
                .set({ milliseconds: 0 })
                .toISO({ suppressMilliseconds: true }))
            } else {
              xAxis.push(DateTime.fromMillis(dataPoint[0])
                .set({ milliseconds: 0 })
                .toUTC()
                .toISO({ suppressMilliseconds: true }))
            }
          }
        }
      }

      // Speed data
      const speedAxis = []
      if (rawGraphData.speed) {
        this.padGraphData(rawGraphData.speed, earliestSampleTime)
        for (const dataPoint of rawGraphData.speed) {
          speedAxis.push(dataPoint[1])
          if (this.selectedDataType.name === 'OPTICAL_100G') {
            this.graphSpeedMin = Math.min(this.graphSpeedMin, dataPoint[1])
            this.graphSpeedMax = Math.max(this.graphSpeedMax, dataPoint[1])
          }
          if (!doneXAxis) {
            if (this.useLocalTime) {
              xAxis.push(DateTime.fromMillis(dataPoint[0])
                .set({ milliseconds: 0 })
                .toISO({ suppressMilliseconds: true }))
            } else {
              xAxis.push(DateTime.fromMillis(dataPoint[0])
                .set({ milliseconds: 0 })
                .toUTC()
                .toISO({ suppressMilliseconds: true }))
            }
          }
        }
      }

      const lane4Axis = []
      if (rawGraphData.lane4) {
        this.padGraphData(rawGraphData.lane4, earliestSampleTime)
        for (const dataPoint of rawGraphData.lane4) {
          lane4Axis.push(dataPoint[1])
          this.graphLane4Min = Math.min(this.graphLane4Min, dataPoint[1])
          this.graphLane4Max = Math.max(this.graphLane4Max, dataPoint[1])
          if (!doneXAxis) {
            if (this.useLocalTime) {
              xAxis.push(DateTime.fromMillis(dataPoint[0])
                .set({ milliseconds: 0 })
                .toISO({ suppressMilliseconds: true }))
            } else {
              xAxis.push(DateTime.fromMillis(dataPoint[0])
                .set({ milliseconds: 0 })
                .toUTC()
                .toISO({ suppressMilliseconds: true }))
            }
          }
        }
      }

      const lane5Axis = []
      if (rawGraphData.lane5) {
        this.padGraphData(rawGraphData.lane5, earliestSampleTime)
        for (const dataPoint of rawGraphData.lane5) {
          lane5Axis.push(dataPoint[1])
          this.graphLane5Min = Math.min(this.graphLane5Min, dataPoint[1])
          this.graphLane5Max = Math.max(this.graphLane5Max, dataPoint[1])
          if (!doneXAxis) {
            if (this.useLocalTime) {
              xAxis.push(DateTime.fromMillis(dataPoint[0])
                .set({ milliseconds: 0 })
                .toISO({ suppressMilliseconds: true }))
            } else {
              xAxis.push(DateTime.fromMillis(dataPoint[0])
                .set({ milliseconds: 0 })
                .toUTC()
                .toISO({ suppressMilliseconds: true }))
            }
          }
        }
      }

      const lane6Axis = []
      if (rawGraphData.lane6) {
        this.padGraphData(rawGraphData.lane6, earliestSampleTime)
        for (const dataPoint of rawGraphData.lane6) {
          lane6Axis.push(dataPoint[1])
          this.graphLane6Min = Math.min(this.graphLane6Min, dataPoint[1])
          this.graphLane6Max = Math.max(this.graphLane6Max, dataPoint[1])
          if (!doneXAxis) {
            if (this.useLocalTime) {
              xAxis.push(DateTime.fromMillis(dataPoint[0])
                .set({ milliseconds: 0 })
                .toISO({ suppressMilliseconds: true }))
            } else {
              xAxis.push(DateTime.fromMillis(dataPoint[0])
                .set({ milliseconds: 0 })
                .toUTC()
                .toISO({ suppressMilliseconds: true }))
            }
          }
        }
      }

      const lane7Axis = []
      if (rawGraphData.lane7) {
        this.padGraphData(rawGraphData.lane7, earliestSampleTime)
        for (const dataPoint of rawGraphData.lane7) {
          lane7Axis.push(dataPoint[1])
          this.graphLane7Min = Math.min(this.graphLane7Min, dataPoint[1])
          this.graphLane7Max = Math.max(this.graphLane7Max, dataPoint[1])
          if (!doneXAxis) {
            if (this.useLocalTime) {
              xAxis.push(DateTime.fromMillis(dataPoint[0])
                .set({ milliseconds: 0 })
                .toISO({ suppressMilliseconds: true }))
            } else {
              xAxis.push(DateTime.fromMillis(dataPoint[0])
                .set({ milliseconds: 0 })
                .toUTC()
                .toISO({ suppressMilliseconds: true }))
            }
          }
        }
      }

      const lane8Axis = []
      if (rawGraphData.lane8) {
        this.padGraphData(rawGraphData.lane8, earliestSampleTime)
        for (const dataPoint of rawGraphData.lane8) {
          lane8Axis.push(dataPoint[1])
          this.graphLane8Min = Math.min(this.graphLane8Min, dataPoint[1])
          this.graphLane8Max = Math.max(this.graphLane8Max, dataPoint[1])
          if (!doneXAxis) {
            if (this.useLocalTime) {
              xAxis.push(DateTime.fromMillis(dataPoint[0])
                .set({ milliseconds: 0 })
                .toISO({ suppressMilliseconds: true }))
            } else {
              xAxis.push(DateTime.fromMillis(dataPoint[0])
                .set({ milliseconds: 0 })
                .toUTC()
                .toISO({ suppressMilliseconds: true }))
            }
          }
        }
      }

      const inGraph = {
        x: xAxis,
        y: inAxis,
        type: 'scatter',
        mode: 'lines',
        name: `${this.selectedDataType.subtypes[0]} ${this.selectedDataType.outputUnit.name}`,
        rangemode: 'tozero',
        line: {
          color: this.graphInColor,
        },
        connectgaps: false,
      }

      const outGraph = {
        x: xAxis,
        y: outAxis,
        type: 'scatter',
        mode: 'lines',
        name: this.selectedDataType.subtypes.length > 1 ? `${this.selectedDataType.subtypes[1]} ${this.selectedDataType.outputUnit.name}` : '',
        rangemode: 'tozero',
        line: {
          color: this.graphOutColor,
        },
        connectgaps: false,
      }

      const speedGraph = {
        x: xAxis,
        y: speedAxis,
        type: 'scatter',
        mode: 'lines',
        name: this.selectedDataType.subtypes.length > 2 ? `${this.selectedDataType.subtypes[2]} ${this.selectedDataType.outputUnit.name}` : '',
        rangemode: 'tozero',
        line: {
          color: this.graphSpeedColor,
        },
        connectgaps: false,
      }

      const lane4Graph = {
        x: xAxis,
        y: lane4Axis,
        type: 'scatter',
        mode: 'lines',
        name: this.selectedDataType.name === 'OPTICAL_100G' ? `${this.selectedDataType.subtypes[3]} ${this.selectedDataType.outputUnit.name}` : '',
        rangemode: 'tozero',
        line: {
          color: this.graphLane4Color,
        },
        connectgaps: false,
      }

      const lane5Graph = {
        x: xAxis,
        y: lane5Axis,
        type: 'scatter',
        mode: 'lines',
        name: this.selectedDataType.name === 'OPTICAL_100G' ? `${this.selectedDataType.subtypes[4]} ${this.selectedDataType.outputUnit.name}` : '',
        rangemode: 'tozero',
        line: {
          color: this.graphLane5Color,
        },
        connectgaps: false,
      }

      const lane6Graph = {
        x: xAxis,
        y: lane6Axis,
        type: 'scatter',
        mode: 'lines',
        name: this.selectedDataType.name === 'OPTICAL_100G' ? `${this.selectedDataType.subtypes[5]} ${this.selectedDataType.outputUnit.name}` : '',
        rangemode: 'tozero',
        line: {
          color: this.graphLane6Color,
        },
        connectgaps: false,
      }

      const lane7Graph = {
        x: xAxis,
        y: lane7Axis,
        type: 'scatter',
        mode: 'lines',
        name: this.selectedDataType.name === 'OPTICAL_100G' ? `${this.selectedDataType.subtypes[6]} ${this.selectedDataType.outputUnit.name}` : '',
        rangemode: 'tozero',
        line: {
          color: this.graphLane7Color,
        },
        connectgaps: false,
      }

      const lane8Graph = {
        x: xAxis,
        y: lane8Axis,
        type: 'scatter',
        mode: 'lines',
        name: this.selectedDataType.name === 'OPTICAL_100G' ? `${this.selectedDataType.subtypes[7]} ${this.selectedDataType.outputUnit.name}` : '',
        rangemode: 'tozero',
        line: {
          color: this.graphLane8Color,
        },
        connectgaps: false,
      }

      return {
        inGraph,
        outGraph,
        speedGraph,
        lane4Graph,
        lane5Graph,
        lane6Graph,
        lane7Graph,
        lane8Graph,
      }
    },
  },
}
</script>

<style>
/* Fix for clear button not hiding in datepicker even when clearable=false is set */
.graph-date-range .el-picker-panel__footer button:first-of-type {
  display: none;
}
</style>

<style lang="scss" scoped>
.info-bar {
  background-color: var(--color-white);
  border: 1px solid var(--color-warning);
  border-radius: var(--border-radius-base);
  padding: 2rem;
  text-align: center;
  width: fit-content;
  margin: 2rem auto;
  line-height: normal;
}
.vue-plotly.plotly-graph.js-plotly-plot {
  min-height: 400px;
  margin-bottom: 1rem;
}
.metricSelect {
  width: 130px;
}
.durationSelect {
  width: 140px;
}
.sourceSelect {
  width: 250px;
}
</style>
